import styled from 'styled-components'

export const BackgroundImage = styled.div`
  width: calc(100% - 52rem);
  background: radial-gradient(
    circle,
    rgba(88, 138, 181, 1) 0%,
    rgba(24, 103, 173, 1) 100%,
    rgba(12, 35, 56, 1) 100%
  );

  box-shadow: -14px 1px 28px -16px rgba(0, 0, 0, 0.48) inset;
  -webkit-box-shadow: -14px 1px 28px -16px rgba(0, 0, 0, 0.48) inset;
  -moz-box-shadow: -14px 1px 28px -16px rgba(0, 0, 0, 0.48) inset;

  display: flex;
  justify-content: center;
  align-items: center;

  & img.logo {
    height: clamp(9rem, 10vw, 13rem);
  }

  @media (max-width: 768px) {
    > img.logo {
      display: none;
    }
  }
`
