import styled from 'styled-components'
import cameraIcon from '../../assets/images/camera.png'

export const MapPlaceHolder = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 2rem;
  padding: 0 clamp(4rem, 6vw, 8rem);
  text-align: center;
  color: tomato;
  line-height: 1.5;
  display: flex;
  background: ${props => props.theme.colors.form.input.background};
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > p {
    margin-top: 1rem;
  }

  & a {
    font-weight: bold;
  }
`

export const MapBox = styled.div`
  background: #8aa1af;
  border: 1px solid ${props => props.theme.colors.form.input.border};
  border-radius: 20px;
  margin-bottom: 3.2rem;

  > footer {
    padding: 15px 0;
    text-align: center;

    > a {
      line-height: 14px;
      font-weight: bold;
      color: #fff;
      text-decoration: none;
    }
  }

  > .leaflet-container {
    border-bottom: 1px solid #dde3f0;
    border-radius: 20px;
  }
`

export const PhotosContainer = styled.div`
  display: grid;
  grid-template-columns: 90px repeat(auto-fill, minmax(90px, 1fr)); //repeat(4, 90px);
  grid-gap: 16px;
  margin-top: 0.8rem;

  > .add-photo {
    width: 100%;
    height: 96px;
    background: #638091 url(${cameraIcon}) no-repeat center;
    border: 1px solid #fff;
    border-radius: 20px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  > .add-photo.disabled {
    background: #7f878a url(${cameraIcon}) no-repeat center;
    cursor: not-allowed;
  }

  > div {
    position: relative;
  }

  > div > img {
    width: 100%;
    height: 96px;
    object-fit: cover;
    border-radius: 20px;
  }

  > div > a {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    background: rgba(255, 255, 255, 0.7);
    /* Back / Lines in white */

    /* border: 1px solid #d3e2e5;  */
    box-sizing: border-box;
    border-radius: 0px 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    font-size: 1.4rem;
    color: #dd5955;
  }
`

export const Popup = styled.div`
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.85);

  display: flex;
  justify-content: center;
  align-items: center;
`

export const CameraContainer = styled.div`
  width: 780px;
  height: 588px;

  margin: 0 auto;
  border-radius: 20px;
  background: white;

  text-align: center;
  font-size: 1.8rem;
  line-height: 1.5;
  color: tomato;

  & video {
    border-radius: 20px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
