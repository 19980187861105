import React from 'react'

import { Link } from 'react-router-dom'

import { BackgroundImage } from './styles'
import logoImage from '../../assets/images/logo-white.png'

const AuthBackground: React.FC = () => {
  return (
    <BackgroundImage>
      <Link to="/">
        <img className="logo" src={logoImage} />
      </Link>
    </BackgroundImage>
  )
}

export default AuthBackground
