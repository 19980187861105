import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useHistory, useLocation } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'

// import { RecoveryLink } from './styles'
import InputBlock from '../../components/InputBlock'
import { AuthBox } from '../../components/AuthBox'
import { AuthContainer } from '../../components/AuthContainer'
import AuthBackground from '../../components/AuthBackground'

// validation schema
const schema = yup.object().shape({
  email: yup
    .string()
    .email('Informe um e-mail válido')
    .required('Informe seu e-mail'),
  password: yup.string().required('Informe sua senha')
})

interface LocationState {
  from: {
    pathname: string
  }
}

const Sigin: React.FC = () => {
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  // hooks
  const history = useHistory()
  const location = useLocation<LocationState>()
  const auth = useAuth()

  // states
  const [message, setMessage] = useState<string | null>(null)

  // submits data to server
  const onSubmit = (data: any) => {
    const { email, password } = data
    const { from } = location.state || { from: { pathname: '/' } }

    auth
      .signin(email, password)
      .then(() => {
        // navigates without pushing a new history entry
        history.replace(from)
      })
      .catch((error: any) => {
        const { code } = error.response?.data || ''

        switch (code) {
          case 101:
            setMessage('Usuário e/ou senha inválidos')
            break
          case 999:
            setMessage('Usuário desativado. Contacte o administrador')
            break
          default:
            setMessage('Erro inesperado. Tente novamente')
        }
      })
  }

  return (
    <AuthContainer>
      <AuthBackground />
      <AuthBox>
        <main>
          <h1>Olá, bem vindo!</h1>
          <p>Identifique-se para continuar</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputBlock>
              <label htmlFor="name">E-mail</label>
              <input
                autoFocus
                name="email"
                aria-invalid={errors.email ? 'true' : 'false'}
                type="email"
                ref={register}
              />
              <span className="error">{errors.email?.message}</span>
            </InputBlock>
            <InputBlock>
              <label htmlFor="name">Senha</label>
              <input
                name="password"
                aria-invalid={errors.password ? 'true' : 'false'}
                type="password"
                ref={register}
              />
              <span className="error">{errors.password?.message}</span>
            </InputBlock>
            <InputBlock>
              {/* <RecoveryLink>
                <Link to="/senha/recuperar">Esqueci minha senha</Link>
              </RecoveryLink>  */}
              <span className="server-error">{message}</span>
              <button
                type="submit"
                disabled={!formState.isValid}
                className="primary-button"
              >
                Entrar
              </button>
            </InputBlock>
          </form>
        </main>
      </AuthBox>
    </AuthContainer>
  )
}

export default Sigin
