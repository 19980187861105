import React, { useState, useContext, createContext, useEffect } from 'react'
import { authAPI } from '../services/api'

interface LoggedUser {
  uid: string
  name: string
  email: string
  isAdmin: boolean
}

interface AuthState {
  user: LoggedUser | null
  pending: boolean
}

interface AuthContext {
  authState: AuthState
  signin: Function
  signout: Function
}

const authContext = createContext<AuthContext>({
  authState: { user: null, pending: true },
  signin: () => {},
  signout: () => {}
})

// Provider hook that creates auth object and handles state
function useAuthProvider(): AuthContext {
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    pending: true
  })

  const signin = (email: string, password: string) => {
    return authAPI.signin(email, password).then(() =>
      // fectch user data
      authAPI.currentUser().then(response => {
        const { user } = response
        setAuthState({
          user: user,
          pending: false
        })
      })
    )
  }

  // signs user out
  const signout = () => {
    authAPI.signout().then(() =>
      setAuthState({
        user: null,
        pending: false
      })
    )
  }

  // Subscribe to user on mount
  useEffect(() => {
    // session token is stored?
    if (localStorage.session) {
      // fectch user data
      authAPI
        .currentUser()
        .then(response => {
          const { user } = response
          setAuthState({
            user: user,
            pending: false
          })
        })
        .catch(() => {
          // could nnot get logged user
          setAuthState({
            user: null,
            pending: false
          })
        })
    } else {
      // no token, unchecks pending status
      setAuthState({
        user: null,
        pending: false
      })
    }
  }, [])

  // Returns the user object and auth methods
  return {
    authState,
    signin,
    signout
  }
}

// Provider component that wraps the app and makes auth object
// available to any child component that calls useAuth().
export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuthProvider()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}
