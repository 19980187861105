import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Container } from './styles'

const Sidebar: React.FC<{ backLink?: string; createLink?: string }> = ({
  backLink = null,
  createLink = null
}) => {
  // hooks
  const history = useHistory()

  // go back to last route
  const goBack = () => {
    history.goBack()
  }

  return (
    <Container>
      {createLink && <Link className="create" to={createLink} title="Criar" />}
      {!backLink && <a className="back" onClick={goBack} title="Voltar" />}
      {backLink && <Link to={backLink} className="back" title="Voltar" />}
    </Container>
  )
}

export default Sidebar
