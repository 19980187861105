import { authenticatedRequest } from './config'

// Uploads a file
export const upload = (name: string, data: Blob) =>
  authenticatedRequest
    .post(`files/${name}`, data)
    .then(response => {
      const { url } = response.data
      return url
    })
    .catch(error => {
      return Promise.reject(error)
    })
