/* eslint-disable multiline-ternary */
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'

import { IVisit } from '../../services/api/visit'
import PageContainer from '../../components/PageContainer'
import Sidebar from '../../components/Sidebar'
import TitleBar from '../../components/TitleBar'
import Form from '../../components/Form'
import InputBlock from '../../components/InputBlock'
import {
  CameraContainer,
  MapBox,
  MapPlaceHolder,
  PhotosContainer,
  Popup
} from './styles'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { dataURItoBlob, mapIconPending } from '../../utils'

import 'leaflet/dist/leaflet.css'
import { fileAPI, restaurantAPI, visitAPI } from '../../services/api'
import { IRestaurant } from '../../services/api/restaurant'
import { useAuth } from '../../hooks/useAuth'
import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'

import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import loadingGIF from '../../assets/images/loading.gif'
import SuccessfulAction from '../../components/SuccessfulAction'

// validation schema
const schema = yup.object().shape({
  restaurant: yup.object().shape({
    objectId: yup
      .string()
      .required('É necessário estar próximo de algum restaurante')
  })
})

interface Photo {
  id: number
  content: Blob
  url: string
}

const VisitForm: React.FC = () => {
  const _isMounted = useRef(true)

  let photoId = 1

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  // hooks
  const { id } = useParams<{ id: string }>()
  const auth = useAuth()
  // const history = useHistory()

  // states
  const [location, setLocation] = useState<[number, number]>([0, 0])
  const [showPopup, setShowPopup] = useState(false)
  const [photos, setPhotos] = useState<Photo[]>([])
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([])
  const [loading, setLoading] = useState(false)
  const [visit, setVisit] = useState<IVisit | null>(null)
  const [title, setTitle] = useState('Registrar')
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const [saving, setSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successfulSaved, setSucceededSaved] = useState(false)

  const [cameraError, setCameraError] = useState(false)
  const [geolocationError, setGeolocationError] = useState(false)

  // gets current location
  const getCurrentLocation: Promise<[number, number]> = new Promise(
    (resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords
            resolve([latitude, longitude])
          },
          error => {
            reject(error)
          }
        )
      } else {
        reject(new Error('Your location could not be determined.'))
      }
    }
  )

  // loads current location
  const showLocation = () => {
    // first, gets current location
    getCurrentLocation
      .then(location => {
        if (_isMounted.current) {
          setLocation(location)
          setGeolocationError(false)
          // Then gets near by restaurants
          restaurantAPI
            .getActiveNearby({
              latitude: location[0],
              longitude: location[1]
            })
            .then(restaurants => {
              setRestaurants(restaurants)
            })
        }
      })
      .catch(() => setGeolocationError(true))
  }

  // loads initial data
  useEffect(() => {
    // loads object data if id is present
    if (id) {
      setLoading(true)
      visitAPI
        .get(id)
        .then(visit => {
          setVisit(visit)
          const location: [number, number] = [
            visit.location.latitude,
            visit.location.longitude
          ]
          setLocation(location)
        })
        .finally(() => {
          setLoading(false)
          setTitle('Consultar')
        })
    } else {
      showLocation()
    }

    return () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false
    }
  }, [])

  // toggles popup
  const togglePopup = () => {
    setShowPopup(!showPopup)
    document.body.style.overflow = showPopup ? 'unset' : 'hidden'
  }

  // handles take photo
  const handleTakePhoto = (dataUri: string) => {
    // closes popup
    togglePopup()

    const photo = dataURItoBlob(dataUri)
    const photoObj: Photo = {
      id: photoId++,
      content: photo,
      url: URL.createObjectURL(photo)
    }

    // appends photo file to the list
    setPhotos(photos.concat(photoObj))
  }

  // submits form data
  const onSubmit = async (data: IVisit) => {
    setSaving(true)
    const uploadFileRequests: Promise<AxiosResponse>[] = []
    photos.forEach((photo, index) => {
      // upload photo request
      uploadFileRequests.push(
        fileAPI
          .upload(
            `${data.restaurant?.objectId}-${Date.now()}-${index}.jpg`,
            photo.content
          )
          .catch(() =>
            setErrorMessage(
              'Não foi possível fazer upload das imagens. Tente novamente'
            )
          )
      )
    })
    // send all upload photo requests first
    Promise.all(uploadFileRequests)
      .then(async responses => {
        // gets saved files name
        const uploadedPhotos = responses.map(url => ({
          src: url
        }))

        // build visit object
        const visit: IVisit = {
          user: {
            __type: 'Pointer',
            className: '_User',
            objectId: auth.authState.user?.uid
          },
          restaurant: {
            __type: 'Pointer',
            className: 'Restaurant',
            objectId: data.restaurant?.objectId
          },
          location: {
            __type: 'GeoPoint',
            latitude: location[0],
            longitude: location[1]
          },
          photos: {
            __op: 'AddUnique',
            objects: uploadedPhotos
          },
          notes: data.notes
        }

        // Saves
        await visitAPI.create(visit)
        // redirects to home
        // history.push('/')
        setSucceededSaved(true)
      })
      .catch(() => {
        setErrorMessage('Não foi possível salvar a visita. Tente novamente')
      })

    setSaving(false)
  }

  function ChangeView({
    center,
    zoom
  }: {
    center: [number, number]
    zoom: number
  }) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  if (id && !visit && !loading) {
    return <h1>Not found</h1>
  }

  if (successfulSaved) {
    return (
      <SuccessfulAction
        title="Tudo certo!"
        message="Sua visita foi registrada com sucesso e já pode ser consultada no mapa."
        returnLink="/"
        returnText="Voltar para o mapa"
      />
    )
  }

  return (
    <>
      <Sidebar />
      <PageContainer>
        <TitleBar title={title} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <legend>Visita</legend>
            <MapBox>
              {geolocationError && !visit && (
                <MapPlaceHolder>
                  <h3>Não foi possível obter localização atual</h3>
                  <p>
                    Certifique-se que o GPS esteja ativo e que o Food Hunter
                    tenha permissão para acessar sua localização. <br /> Caso o
                    GPS esteja desativado, será necessário{' '}
                    <a
                      onClick={() => {
                        window.location.reload()
                        return false
                      }}
                    >
                      atualizar
                    </a>{' '}
                    a página após ativá-lo.
                  </p>
                </MapPlaceHolder>
              )}
              {(!geolocationError || (geolocationError && visit !== null)) && (
                <MapContainer
                  center={location}
                  zoom={16}
                  style={{ width: '100%', height: '200px' }}
                  dragging={visit !== null}
                  touchZoom={false}
                  zoomControl={false}
                  scrollWheelZoom={visit !== null}
                  doubleClickZoom={visit !== null}
                >
                  <ChangeView center={location} zoom={16} />
                  <TileLayer
                    url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                  />
                  <Marker
                    interactive={false}
                    icon={mapIconPending}
                    position={location}
                  />
                </MapContainer>
              )}

              {!visit && (
                <footer>
                  <a onClick={() => showLocation()}>
                    Clique para atualizar sua localização
                  </a>
                </footer>
              )}
            </MapBox>

            {id && (
              <InputBlock>
                <label htmlFor="restaurant.name">Restaurante</label>
                <input
                  name="restaurant.name"
                  defaultValue={visit?.restaurant?.name}
                  disabled={true}
                />
              </InputBlock>
            )}
            {!visit && (
              <InputBlock>
                <label htmlFor="restaurant.objectId">
                  Restaurantes próximos <span>Em até 1km de distância</span>
                </label>

                <select
                  autoFocus
                  name="restaurant.objectId"
                  ref={register}
                  aria-invalid={errors.restaurant?.objectId ? 'true' : 'false'}
                >
                  {restaurants.map((restaurant: IRestaurant, index) => (
                    <option key={index} value={restaurant.objectId}>
                      {restaurant.name}
                    </option>
                  ))}
                </select>
                <span className="error">
                  {errors.restaurant?.objectId?.message}
                </span>
              </InputBlock>
            )}

            {!visit && (
              <InputBlock>
                <label>Fotos</label>
                <PhotosContainer>
                  <label
                    onClick={() => {
                      if (photos.length <= 3) {
                        togglePopup()
                      }
                    }}
                    className={`add-photo ${
                      photos.length > 3 ? 'disabled' : ''
                    }`}
                  />

                  {photos.map((photo, index) => {
                    return (
                      <div key={index}>
                        <img src={photo.url} />
                        <a
                          onClick={() => {
                            setPhotos(photos.filter((p, i) => i !== index))
                          }}
                        >
                          x
                        </a>
                      </div>
                    )
                  })}
                </PhotosContainer>
              </InputBlock>
            )}
            {visit && visit.photos.length > 0 && (
              <InputBlock>
                <label>Fotos</label>
                <PhotosContainer>
                  {isOpen && (
                    <Lightbox
                      mainSrc={visit.photos[photoIndex].src}
                      nextSrc={
                        visit.photos[(photoIndex + 1) % visit.photos.length].src
                      }
                      prevSrc={
                        visit.photos[
                          (photoIndex + visit.photos.length - 1) %
                            visit.photos.length
                        ].src
                      }
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + visit.photos.length - 1) %
                            visit.photos.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % visit.photos.length)
                      }
                      onCloseRequest={() => setIsOpen(false)}
                    />
                  )}
                  {visit.photos.map((photo: { src: string }, index: number) => {
                    return (
                      <div key={index}>
                        <img src={photo.src} onClick={() => setIsOpen(true)} />
                      </div>
                    )
                  })}
                </PhotosContainer>
              </InputBlock>
            )}
            <InputBlock>
              <label htmlFor="notes">Notas</label>
              <textarea
                name="notes"
                aria-invalid={errors.notes ? 'true' : 'false'}
                defaultValue={visit?.notes}
                disabled={visit !== null}
                rows={4}
                ref={register}
              />
              <span className="error">{errors.notes?.message}</span>
            </InputBlock>
            {!visit && (
              <InputBlock>
                {saving && <img src={loadingGIF} />}
                {!saving && (
                  <span className="server-error">{errorMessage}</span>
                )}

                <button type="submit" className="primary-button">
                  Salvar
                </button>
              </InputBlock>
            )}
            {visit && (
              <InputBlock>
                <label>
                  Por&nbsp;<b>{visit?.user?.name}</b>&nbsp;em &nbsp;
                  <b>
                    {format(
                      new Date(visit?.createdAt || ''),
                      "dd MMM 'às' HH:mm"
                    )}
                  </b>
                </label>
              </InputBlock>
            )}
          </fieldset>
        </Form>
        {showPopup ? (
          <Popup onClick={() => togglePopup()}>
            <CameraContainer>
              {cameraError && (
                <p>
                  Não foi possível acessar câmera do seu dispositivo. <br />
                  Verifique permissões no seu navegador
                </p>
              )}
              {!cameraError && (
                <Camera
                  imageType={IMAGE_TYPES.JPG}
                  imageCompression={0.97}
                  onCameraError={() => setCameraError(true)}
                  onTakePhoto={dataUri => {
                    handleTakePhoto(dataUri)
                  }}
                />
              )}
            </CameraContainer>
          </Popup>
        ) : null}
      </PageContainer>
    </>
  )
}

export default VisitForm
