import React, { useState, useEffect } from 'react'

import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import { Link, NavLink, useHistory } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'
import { format } from 'date-fns'

import { restaurantAPI } from '../../services/api'
import { IRestaurant } from '../../services/api/restaurant'

import { averageGeolocation, mapIconOk, mapIconPending } from '../../utils'
import { Container, Sidebar } from './styles'

const Home: React.FC = () => {
  // hooks
  const auth = useAuth()
  const history = useHistory()

  // states
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([])
  const [centerLocation, setCenterLocation] = useState<[number, number]>([0, 0])

  // loads initial data
  useEffect(() => {
    restaurantAPI.getAll().then(restaurants => {
      setRestaurants(restaurants)

      // all restaurants coords
      const coords = restaurants.map(restaurant => ({
        lat: restaurant.location?.latitude || 0,
        lng: restaurant.location?.longitude || 0
      }))
      if (coords.length > 0) {
        setCenterLocation(averageGeolocation(coords))
      }
    })
  }, [])

  // signs user out
  const signout = () => {
    auth?.signout(() => history.push('/'))
  }

  // Changes map view
  function ChangeView({
    center,
    zoom
  }: {
    center: [number, number]
    zoom: number
  }) {
    const map = useMap()
    map.setView(center, zoom)
    return null
  }

  const mapZoom = 13

  return (
    <Container>
      <Sidebar>
        <header>
          <h1>Escolha um restaurante no mapa</h1>
          <p>Clique para consultar as visitas realizadas e editar dados</p>
        </header>
        {auth.authState.user?.isAdmin && (
          <section>
            <Link className="restaurants" to="/restaurantes/criar" />
            <Link className="users" to="/usuarios" />
          </section>
        )}
        <footer>
          <strong>{auth.authState.user?.name}</strong>
          <p onClick={signout}>Desconectar</p>
        </footer>
      </Sidebar>
      <MapContainer
        center={centerLocation}
        zoom={mapZoom}
        style={{ width: '100%', height: '100vh' }}
        dragging={true}
        touchZoom={true}
        zoomControl={true}
        scrollWheelZoom={true}
        doubleClickZoom={true}
      >
        <ChangeView center={centerLocation} zoom={mapZoom} />
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
        />
        {restaurants.map((restaurant: IRestaurant, index) => (
          <Marker
            key={index}
            icon={
              (restaurant.visits?.length || 0) > 0 ? mapIconOk : mapIconPending
            }
            position={[
              restaurant.location?.latitude || 0,
              restaurant.location?.longitude || 0
            ]}
          >
            <Popup
              closeButton={false}
              minWidth={248}
              maxWidth={248}
              className="map-popup"
            >
              <h5>{restaurant.name}</h5>
              <p>{restaurant.address}</p>

              {(restaurant.visits?.length || 0) > 0 && (
                <h6>Visitas realizadas</h6>
              )}

              <ul>
                {restaurant.visits?.map((visit, index) => (
                  <li key={index}>
                    <Link to={`/visitas/${visit.objectId}`}>
                      {format(
                        new Date(visit.createdAt || ''),
                        "dd MMM 'às' HH:mm"
                      )}
                    </Link>
                  </li>
                ))}
              </ul>

              {auth.authState.user?.isAdmin && (
                <NavLink
                  className="edit"
                  to={`/restaurantes/${restaurant.objectId}`}
                />
              )}
            </Popup>
          </Marker>
        ))}
        <NavLink className="create" to="/visitas/criar" />
      </MapContainer>
    </Container>
  )
}

export default Home
