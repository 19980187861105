import styled from 'styled-components'

export default styled.form`
  padding: 6.4rem 8rem;
  background: ${props => props.theme.colors.form.background};
  border: 1px solid ${props => props.theme.colors.form.input.border};
  border-radius: 2rem;
  max-width: 70.8rem;
  margin: 0 auto;

  > fieldset {
    border: none;
  }

  > fieldset + fieldset {
    margin-top: 8rem;
  }

  > fieldset legend {
    width: 100%;
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 3.4rem;
    border-bottom: 1px solid ${props => props.theme.colors.form.input.border};
    margin-bottom: 3.2rem;
    padding-bottom: 2.4rem;
    color: ${props => props.theme.colors.form.title};
  }

  @media (max-width: 767px) {
    background: none;
    border: none;
    padding: 0;
    padding-top: 7.8rem;

    margin: 2.4rem;

    > fieldset legend {
      font-size: 2.4rem;
    }
  }
`
