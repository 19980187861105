import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ListCard from '../../components/ListCard'
import ListContainer from '../../components/ListContainer'
import ListItem from '../../components/ListItem'
import PageContainer from '../../components/PageContainer'

import Sidebar from '../../components/Sidebar'
import TitleBar from '../../components/TitleBar'

import loadingGIF from '../../assets/images/loading.gif'
import { userAPI } from '../../services/api'
import { IUser } from '../../services/api/user'

const UserList: React.FC = () => {
  // states
  const [users, setUsers] = useState<IUser[]>([])
  const [loading, setLoading] = useState(false)

  // loads initial data
  useEffect(() => {
    setLoading(true)
    userAPI
      .getAll()
      .then(users => {
        setUsers(users)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      <Sidebar backLink="/" createLink="/usuarios/criar" />
      <PageContainer>
        <TitleBar title="Usuários" backLink="/" small={false} />
        <ListContainer>
          {loading && <img src={loadingGIF} />}
          {users.map((user: IUser, index) => (
            <ListItem key={index}>
              <Link to={`/usuarios/${user.objectId}`}>
                <ListCard>
                  <h3>{user.name}</h3>
                  <p>{user.username}</p>
                </ListCard>
              </Link>
            </ListItem>
          ))}
        </ListContainer>
      </PageContainer>
    </>
  )
}

export default UserList
