import Leaflet from 'leaflet'
import markerPendingImage from '../assets/images/marker-warning.png'
import markerOkImage from '../assets/images/marker.png'

export const mapIconPending = Leaflet.icon({
  iconUrl: markerPendingImage,

  // iconSize: [50, 90],
  iconAnchor: [29, 68],
  // popupAnchor: [0, -60]
  popupAnchor: [-15, -80]
})

export const mapIconOk = Leaflet.icon({
  iconUrl: markerOkImage,

  // iconSize: [50, 90],
  iconAnchor: [29, 68],
  // popupAnchor: [0, -60]
  popupAnchor: [-15, -80]
})

export const averageGeolocation = (
  coords: { lat: number; lng: number }[]
): [number, number] => {
  if (coords.length === 1) {
    return [coords[0].lat, coords[0].lng]
  }

  let x = 0.0
  let y = 0.0
  let z = 0.0

  for (const coord of coords) {
    const latitude = (coord.lat * Math.PI) / 180
    const longitude = (coord.lng * Math.PI) / 180

    x += Math.cos(latitude) * Math.cos(longitude)
    y += Math.cos(latitude) * Math.sin(longitude)
    z += Math.sin(latitude)
  }

  const total = coords.length

  x = x / total
  y = y / total
  z = z / total

  const centralLongitude = Math.atan2(y, x)
  const centralSquareRoot = Math.sqrt(x * x + y * y)
  const centralLatitude = Math.atan2(z, centralSquareRoot)

  return [(centralLatitude * 180) / Math.PI, (centralLongitude * 180) / Math.PI]
}

// converts data URI to blob
// from react-html5-camera-photo demo: https://github.com/MABelanger/react-html5-camera-photo/blob/4be743f8e7992433b75b5eea08f704175763d5e5/src/demo/AppSaveToLocalFile.js
export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })
  return blob
}
