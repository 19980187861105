import styled from 'styled-components'
import leftArrowImage from '../../assets/images/left-arrow.svg'

export const AuthBox = styled.div`
  width: 52rem;
  padding: clamp(4rem, 8vw, 8rem);
  background: ${props => props.theme.colors.authBox.background};

  display: flex;
  flex-direction: column;

  > header {
    > a.back {
      float: right;
      background: ${props => props.theme.colors.authBox.backBtnBackground}
        url(${leftArrowImage}) no-repeat center;
      border-radius: 1.6rem;
      margin-bottom: 4rem;
      width: 4.8rem;
      height: 4.8rem;
      border: none;
      transition: 320ms;
    }

    > a.back:hover {
      background: ${props => props.theme.colors.authBox.backBtnBackgroundHover}
        url(${leftArrowImage}) no-repeat center;
    }
  }

  > main {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    color: ${props => props.theme.colors.authBox.color};

    p {
      font-weight: 400;
      line-height: 1.2;
    }

    > h1 {
      font-weight: bold;
      font-size: clamp(2rem, 8vw, 3.2rem);
      line-height: 3.4rem;
      font-weight: 800;
      padding: 1.5rem 0;
    }

    > form {
      margin-top: 4rem;
    }
  }
`
