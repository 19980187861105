import axios from 'axios'

const environment = process.env.NODE_ENV || 'development'
const API_URL =
  environment === 'production'
    ? 'https://parseapi.back4app.com/'
    : 'http://localhost:1337/parse/'

// default headers
const headers = {
  'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APP_ID,
  'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_API_KEY,
  'Content-Type': 'application/json'
}

// authenticated request
const authenticatedRequest = axios.create({
  baseURL: API_URL,
  headers
})

// plain request
const plainRequest = axios.create({
  baseURL: API_URL,
  headers
})

// Authorize requests with token
authenticatedRequest.interceptors.request.use(config => {
  const { method } = config
  if (method?.toUpperCase() !== 'OPTIONS') {
    config.headers = {
      ...config.headers,
      'X-Parse-Session-Token': localStorage.session
    }
  }
  return config
})

export { authenticatedRequest, plainRequest }
