import styled from 'styled-components'

export default styled.div`
  /* display: grid; */

  background: #fff;
  display: flex;

  color: #444;
  flex-direction: column;
  justify-content: space-between;

  > p {
    color: #777;
  }

  height: 8rem;
  padding: 2.2rem;
  border: 1px solid #d3e2e5;
  border-radius: 2rem;
  width: 100%;
  /* grid-template-columns: 60px 1px 1fr;
  grid-template-rows: 1fr 20px;
  grid-template-areas:
    'figure divider title'
    'figure divider content';
  grid-column-gap: 12px; */

  :hover {
    border: 2px solid #97bbc2;
  }

  @media only screen and (min-width: 960px) {
    height: 10rem;
    /* height: 130px; */
    /* grid-template-columns: 85px 1px 1fr; */
    /* grid-column-gap: 20px; */
    padding: 3rem;
    cursor: pointer;
  }
`
