import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 2.4rem;
  }

  > label {
    display: flex;
    color: ${props => props.theme.colors.form.label};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.1;
    padding-left: 1rem;
  }

  label span {
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${props => props.theme.colors.form.label};
    margin-left: 2.4rem;
  }

  > input,
  select {
    height: 6.6rem;
    padding: 0 2.4rem;
  }

  > textarea {
    padding: 2rem 2.6rem;
    line-height: 2.8rem;
  }

  > input[type='email'] {
    text-transform: lowercase;
  }

  > input:disabled,
  input:read-only,
  textarea:disabled {
    background: ${props => props.theme.colors.form.input.backgroundDisabled};
  }
  > input,
  textarea {
    margin-top: 0.8rem;

    width: 100%;

    background: ${props => props.theme.colors.form.input.background};
    border: 1px solid ${props => props.theme.colors.form.input.border};
    border-radius: 2rem;

    color: ${props => props.theme.colors.form.input.text};
    font-weight: 600;

    font-size: 1.8rem;
  }

  > select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;

    background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24"><path d="M7 10l5 5 5-5z" fill="gray" /><path d="M0 0h24v24H0z" fill="none"/></svg>')
      98% 50% no-repeat ${props => props.theme.colors.form.input.background};

    margin-top: 0.8rem;
    font: 600 1.8rem/1 'Nunito', sans-serif;

    border: 1px solid ${props => props.theme.colors.form.input.border};
    border-radius: 2rem;

    color: ${props => props.theme.colors.form.input.text};
  }

  > input[aria-invalid='false'],
  textarea:valid {
    border: 1px solid ${props => props.theme.colors.form.input.valid};
  }

  > input[aria-invalid='true'],
  textarea[aria-invalid='true'] {
    border: 1px solid ${props => props.theme.colors.form.error};
  }

  > span.error:empty,
  span.server-error:empty {
    display: none;
  }

  > span.error {
    color: ${props => props.theme.colors.form.error};
    font-size: 1.5rem;
    padding: 0.8rem;
  }

  > span.server-error {
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;
    padding-bottom: 2.4rem;
    padding-top: 1.2rem;
    color: ${props => props.theme.colors.form.error};
  }

  > button {
    width: 100%;
    border-radius: 2rem;
    height: 6.4rem;

    font-weight: 800;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: ${props => props.theme.colors.buttonText};
    border: 0;

    cursor: pointer;
    transition: background-color 0.2s;
  }

  .primary-button {
    background: ${props => props.theme.colors.form.button.primary};
  }

  .primary-button:disabled {
    opacity: 0.3;
  }

  .primary-button:not(:disabled):hover {
    background: ${props => props.theme.colors.form.button.primaryHover};
  }

  > .button-select {
    margin-top: 0.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  > .button-select button {
    height: 6.6rem;
    font-size: 1.8rem;

    background: #f5f8fa;
    border: 1px solid #d3e2e5;
    color: #5c8599;
    cursor: pointer;
  }

  > .button-select button.active {
    background: #edfff6;
    border: 1px solid #a1e9c5;
    color: #37c77f;
  }

  > .button-select button:first-child {
    border-radius: 20px 0px 0px 20px;
  }

  > .button-select button:last-child {
    border-radius: 0 20px 20px 0;
    border-left: 0;
  }

  @media (max-width: 767px) {
    :last-child {
      margin-bottom: 2.4rem;
    }

    > input,
    .button-select button {
      height: 5.8rem;
      padding: 0 2rem;
    }

    > label {
      font-size: 1.3rem;
      line-height: 2rem;
    }

    > input,
    textarea,
    .button-select button {
      font-size: 1.5rem;
    }

    > button {
      height: 5.6rem;
    }

    > span.error {
      font-size: 1.3rem;
    }

    > span.server-error {
      font-size: 1.4rem;
    }
  }
`
