import styled from 'styled-components'

import editIcon from '../../assets/images/edit.svg'
import addIcon from '../../assets/images/Union.svg'
import restaurantIcon from '../../assets/images/restaurant.svg'
import usersIcon from '../../assets/images/users.svg'

export const Sidebar = styled.aside`
  width: clamp(15rem, 35vw, 40rem);
  padding: clamp(2.4rem, 6vw, 8rem);

  min-height: 100vh;
  min-height: -webkit-fill-available;
  color: #fff;

  > img {
    align-self: center;
    height: clamp(4.8rem, 10vw, 9rem);
  }

  /* Gradiente / Blue */
  background: linear-gradient(329.54deg, #1867ad 0%, #5ea5d7 100%);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > header {
    margin-top: 3rem;
    margin-bottom: auto;
  }

  > header h1 {
    font-size: clamp(1.8rem, 3.5vw, 4rem);
    line-height: 1.1;
    font-weight: 800;
  }

  > header p {
    font-size: clamp(1.2rem, 1.5vw, 1.8rem);
    line-height: 1.4;
    font-weight: 600;

    margin-top: 2.4rem;
    margin-bottom: clamp(6rem, 8vw, 8rem);
  }

  > section {
    flex: 1;

    display: flex;

    margin-bottom: 8rem;

    > a {
      display: block;
      background: rgba(255, 255, 255, 0.2);
      background-repeat: no-repeat;
      background-position: center;
      border-radius: clamp(1rem, 1vw, 1.6rem);

      width: clamp(3.4rem, 5vw, 4.8rem);
      height: clamp(3.4rem, 5vw, 4.8rem);
    }

    > a:hover {
      background: rgba(0, 0, 0, 0.1);
      background-repeat: no-repeat;
      background-position: center;
    }

    > a.restaurants,
    a.restaurants:hover {
      background-image: url(${restaurantIcon});
      background-size: 45%;
    }

    > a.users,
    a.users:hover {
      background-image: url(${usersIcon});
      background-size: 45%;
    }

    > a + a {
      margin-left: clamp(0.1rem, 4vw, 1rem);
    }
  }

  > footer {
    font-size: clamp(1.4rem, 1.5vw, 1.8rem); /* min: 14px / max: 18px */
    line-height: 1.5;
    /* background: yellow; */

    > strong {
      color: #fabc20;
    }
    > p {
      cursor: pointer;
    }
  }
`

export const MobileInfo = styled.div`
  @media (max-width: 767px) {
    position: absolute;
    background: #fff;

    z-index: 499;
    bottom: 4rem;
    height: 6.4rem;
    margin: 0 4rem;
    width: calc(100vw - 8rem);
    border-radius: 2rem;

    color: #737373;

    display: flex;
    align-items: center;

    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: bold;

    padding-left: 2.4rem;
  }
`

export const MobileHeader = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: linear-gradient(329.54deg, #1867ad 0%, #5ea5d7 100%);

    padding: 2.4rem 2.5rem;
    border-bottom: 1px solid #dde3f0;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.2);

    color: #fff;

    > h4 {
      > strong {
        color: #fabc20;
        font-weight: 700;
      }
    }
  }
`

export const Container = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  height: 100vh;

  & a.create {
    position: absolute;
    bottom: 4rem;
    right: 4rem;

    background: #1867ad url(${addIcon}) center no-repeat;
    border-radius: 2rem;

    width: 6.4rem;
    height: 6.4rem;

    transition: 320ms;
    z-index: 500;

    @media (max-width: 767px) {
      width: 5.6rem;
      height: 5.6rem;
    }
  }

  & a.create:hover {
    background-color: #568bba;
  }

  h5 {
    font-size: 1.8rem;
    color: #556f85;
  }
  h6 {
    color: #5e5e5e;
    font-size: 1.4rem;
  }
  ul {
    margin-top: 0.8rem;
    padding-bottom: 1.2rem;
    list-style: none;
  }
  p,
  li {
    font-size: 1.3rem;
  }
  & .map-popup .leaflet-popup-content a.edit {
    display: block;
    background: #ebf2f5 url(${editIcon}) no-repeat center;
    border-radius: 16px;
    border: none;
    width: 4.8rem;
    height: 4.8rem;
  }

  > .map-popup .leaflet-popup-tip-container {
    display: none;
  }
`
