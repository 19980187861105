import styled from 'styled-components'

import backIcon from '../../assets/images/back-white.svg'
import addIcon from '../../assets/images/Union.svg'
export const Container = styled.aside`
  position: fixed;
  display: block;

  width: 9.6rem;
  height: 100vh;
  min-height: -webkit-fill-available;
  padding: 3.2rem 2.4rem;
  background: linear-gradient(
    329.54deg,
    ${props => props.theme.colors.sidebar.gradientColor1} 0%,
    ${props => props.theme.colors.sidebar.gradientColor2} 100%
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > a {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 1.6rem;
    cursor: pointer;
  }

  > .create {
    background: rgba(0, 0, 0, 0.1) url(${addIcon}) no-repeat center;
  }

  > .create:hover {
    background: rgba(0, 0, 0, 0.2) url(${addIcon}) no-repeat center;
  }

  > .back {
    margin-top: auto;
    background: rgba(0, 0, 0, 0.1) url(${backIcon}) no-repeat center;
  }

  > .back:hover {
    background: rgba(0, 0, 0, 0.2) url(${backIcon}) no-repeat center;
  }

  @media (max-width: 767px) {
    display: none;
  }
`
