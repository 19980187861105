import styled from 'styled-components'

export default styled.main`
  margin-left: calc(9.6rem + 4rem);
  margin-right: 4rem;
  font-size: 1.4rem;
  padding-bottom: 3rem;

  @media (max-width: 767px) {
    margin: initial;
    padding-bottom: initial;

    > ul {
      margin-top: 9rem;
      padding-bottom: 3rem;
    }
  }
`
