import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from './styles'

const SuccessfulAction: React.FC<{
  title: string
  message: string
  returnLink: string
  returnText: string
}> = ({ title = 'Tudo certo!', message, returnText, returnLink }) => {
  return (
    <Container>
      <h1>{title}</h1>
      <p>{message}</p>
      <Link to={returnLink} className="action">
        {returnText}
      </Link>
    </Container>
  )
}

export default SuccessfulAction
