import styled from 'styled-components'

export default styled.li`
  height: min-content;
  border-radius: 2rem;

  transition: 200ms;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  text-decoration: none;
`
