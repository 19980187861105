import styled from 'styled-components'

export const Container = styled.div`
  background: ${props => props.theme.colors.unsuccessfulAction.background};
  width: 100%;
  height: 100vh;

  color: ${props => props.theme.colors.unsuccessfulAction.color};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  > h1 {
    font-weight: 800;
    font-size: clamp(4.5rem, 9vw, 6rem);
    line-height: 8rem;
  }

  > p {
    margin-top: 3.2rem;
    font-weight: 600;
    font-size: clamp(2rem, 3.8vw, 2.4rem);

    line-height: 1.4;
    max-width: 40rem;
  }

  > a.action {
    margin-top: 6rem;
    background: ${props => props.theme.colors.unsuccessfulAction.actionButton};
    border-radius: 2rem;

    font-weight: 700;
    font-size: 1.8rem;
    padding: 2rem 4rem;

    transition: 320ms;
  }

  > a.action:hover {
    background: ${props =>
      props.theme.colors.unsuccessfulAction.actionButtonHover};
  }
`
