const theme = {
  colors: {
    primary: '#37C77F',
    primaryHover: '#3EE08F',
    lightPrimary: '#F5F8FA',
    lightPrimaryHover: '#EBF2F5',
    buttonText: '#fff',
    title: '#282829',
    background: '#EDF5FD',

    form: {
      background: '#fff',
      title: '#556f85',
      label: '#666',
      error: '#DD5955',
      input: {
        text: '#686868',
        border: '#D3E2E5',
        background: '#F5F8FA',
        backgroundDisabled: '#F2F2F2',
        valid: '#A0CCC3'
      },
      button: {
        primary: '#6FB7A8',
        primaryHover: '#499182'
      }
    },

    authBox: {
      background: '#fff',
      backBtnBackground: '#EDF2F6',
      backBtnBackgroundHover: '#dce3e8',
      color: '#666'
    },

    sidebar: {
      gradientColor1: '#1867ad',
      gradientColor2: '#5ea5d7',
      btnColor: '#155d9b',
      btnColorHover: '#1b77c7'
    },

    successfulAction: {
      background: '#37c77f',
      color: '#fff',
      actionButton: '#31b272',
      actionButtonHover: '#3bd689'
    },

    unsuccessfulAction: {
      background: '#FF669D',
      color: '#fff',
      actionButton: '#D6487B',
      actionButtonHover: '#EA7EA6'
    },

    titleBar: {
      color: '#8ea2b5',
      smallDevice: {
        background: '#f9fafc',
        border: '#dde3f0',
        color: '#556f85'
      }
    }
  }
}

export default theme
