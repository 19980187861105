import { plainRequest, authenticatedRequest } from './config'

// Signs user in with email and password
export const signin = (email: string, password: string) =>
  plainRequest
    .get('/login', {
      params: { email, password },
      headers: { 'X-Parse-Revocable-Session': '1' }
    })
    .then(response => {
      const user = response.data

      if (!user.active) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: { code: 999, error: 'User is not active' }
          }
        }
      }
      const loggedUser = {
        uid: user.objectId,
        email: user.email
      }
      // saves data
      localStorage.setItem('session', user.sessionToken)
      return { user: loggedUser }
    })
    .catch(error => {
      return Promise.reject(error)
    })

// Signs user out
export const signout = () =>
  authenticatedRequest.post('/logout').then(() => {
    delete localStorage.session
  })

// Gets current logged in user
export const currentUser = () =>
  authenticatedRequest
    .get('/users/me')
    .then(response => {
      const { data } = response
      const loggedUser = {
        uid: data.objectId,
        email: data.email,
        name: data.name,
        isAdmin: data.isAdmin
      }
      return { user: loggedUser }
    })
    .catch(error => {
      const { code } = error.response.data
      // Handle invalid session token
      if (code === 209) {
        // deletes invalid session token
        delete localStorage.session
      }
      return Promise.reject(error)
    })
