import { authenticatedRequest } from './config'
import { IRestaurant } from './restaurant'

// model interface
export interface IVisit {
  objectId?: string
  user?: any
  restaurant?: IRestaurant
  location: {
    __type?: string
    latitude: number
    longitude: number
  }
  notes: string
  photos: any
  createdAt?: string
  ACL?: {}
}

// default model ACL
const defaultACL = {
  'role:SYSADM': {
    read: true,
    write: true
  },
  'role:ADM': {
    read: true,
    write: true
  },
  'role:USER': {
    read: true,
    write: true
  }
}

// Gets specific visit record allowed to logged user
export const get = (id: string) =>
  authenticatedRequest
    .get(`/classes/Visit/${id}?include=restaurant,user`)
    .then(response => {
      const visit: IVisit = response.data
      return visit
    })
    .catch(error => {
      return Promise.reject(error)
    })

// Gets all visit records allowed to logged user
export const getAll = () =>
  authenticatedRequest
    .get('/classes/Visit')
    .then(response => {
      const visits: IVisit[] = response.data.results
      return visits
    })
    .catch(error => {
      return Promise.reject(error)
    })

// Creates a visit record
export const create = (data: IVisit) => {
  data.ACL = defaultACL

  return authenticatedRequest
    .post('/classes/Visit', data)
    .then(response => {
      const { objectId } = response.data
      return objectId
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
