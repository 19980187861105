/* eslint-disable multiline-ternary */
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useAuth } from '../../hooks/useAuth'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  // Get auth state and re-render anytime it changes
  const auth = useAuth()

  if (auth.authState.pending) {
    return null
  }

  return (
    <Route
      {...rest}
      render={props =>
        auth.authState.user ? (
          Component ? (
            <Component {...props} />
          ) : null
        ) : (
          <Redirect
            to={{
              pathname: '/entrar',
              state: { from: props.history.location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
