import { IVisit } from './visit'
import { authenticatedRequest } from './config'

// model interface
export interface IRestaurant {
  __type?: string
  className?: string
  objectId?: string
  name?: string
  address?: string
  location?: any

  // location?: {
  //   __type?: string
  //   latitude: number
  //   longitude: number
  // }
  visits?: IVisit[]
  ACL?: {}
}

// default model ACL
const defaultACL = {
  'role:SYSADM': {
    read: true,
    write: true
  },
  'role:ADM': {
    read: true,
    write: true
  },
  'role:USER': {
    read: true
  }
}
// Gets specific restaurant records allowed to logged user
export const get = (id: string) =>
  authenticatedRequest
    .get(`/classes/Restaurant/${id}`)
    .then(response => {
      const restaurant: IRestaurant = response.data
      return restaurant
    })
    .catch(error => {
      return Promise.reject(error)
    })

// Gets all restaurant records allowed to logged user
export const getAll = () =>
  authenticatedRequest
    .get('/classes/Restaurant', {
      params: {
        include: 'visits',
        where: {
          active: true
        }
      }
    })
    .then(response => {
      const restaurants: IRestaurant[] = response.data.results
      return restaurants
    })
    .catch(error => {
      return Promise.reject(error)
    })

// Gets active nearby restaurants
export const getActiveNearby = (location: {
  latitude: number
  longitude: number
}) =>
  authenticatedRequest
    .get('/classes/Restaurant', {
      params: {
        where: {
          active: true,
          location: {
            $nearSphere: {
              __type: 'GeoPoint',
              latitude: location.latitude,
              longitude: location.longitude
            },
            $maxDistanceInKilometers: 1.0 // 100 meters
          }
        }
      }
    })
    .then(response => {
      const restaurants: IRestaurant[] = response.data.results

      return restaurants
    })
    .catch(error => {
      return Promise.reject(error)
    })

// Creates a restaurant record
export const create = (data: IRestaurant) => {
  // sets location object type
  data.location.__type = 'GeoPoint'
  // sets default ACL for this class
  data.ACL = defaultACL

  return authenticatedRequest
    .post('/classes/Restaurant', data)
    .then(response => {
      const { objectId } = response.data
      return objectId
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

// Updates a restaurant record
export const update = (id: string, data: IRestaurant) => {
  // sets location object type
  data.location.__type = 'GeoPoint'
  // sets default ACL for this class
  data.ACL = defaultACL

  return authenticatedRequest
    .put(`/classes/Restaurant/${id}`, data)
    .then(() => {})
    .catch(error => {
      return Promise.reject(error)
    })
}
