import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { restaurantAPI } from '../../services/api'
import { IRestaurant } from '../../services/api/restaurant'

import Sidebar from '../../components/Sidebar'
import InputBlock from '../../components/InputBlock'
import TitleBar from '../../components/TitleBar'
import PageContainer from '../../components/PageContainer'
import Form from '../../components/Form'

import loadingGIF from '../../assets/images/loading.gif'
import SuccessfulAction from '../../components/SuccessfulAction'

// validation schema
const schema = yup.object().shape({
  name: yup.string().required('Informe um nome'),
  address: yup.string().required('Informe um endereço'),
  location: yup.object().shape({
    latitude: yup
      .number()
      .min(-90, 'Latitude deve ser maior ou igual a -90')
      .max(90, 'Latitude deve ser menor ou igual a 90')
      .transform(value => (isNaN(value) ? undefined : value))
      .required('Informe um valor válido para latitude'),
    longitude: yup
      .number()
      .min(-180, 'Longitude deve ser maior ou igual a -180')
      .max(80, 'Longitude deve ser menor ou igual a 80')
      .transform(value => (isNaN(value) ? undefined : value))
      .required('Informe um valor válido para longitude')
  })
})

const RestaurantForm: React.FC = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  // hooks
  const { id } = useParams<{ id: string }>()

  // states
  const [restaurant, setRestaurant] = useState<IRestaurant | null>(null)
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('Novo')

  const [successfulSaved, setSucceededSaved] = useState(false)

  // loads initial data
  useEffect(() => {
    // loads object data if id is present
    if (id) {
      setLoading(true)
      restaurantAPI
        .get(id)
        .then(restaurant => {
          setRestaurant(restaurant)
        })
        .catch((error: any) => {
          const { code } = error.response.data
          switch (code) {
            // not found
            case 101:
              setRestaurant(null)
              break
            default:
            // TODO: handler other errors
          }
        })
        .finally(() => {
          setLoading(false)
          setTitle('Editar')
        })
    }
  }, [])

  if (id && !restaurant && !loading) {
    return <h1>Not found</h1>
  }

  // submits form data
  const onSubmit = async (data: IRestaurant) => {
    const restaurant = data
    if (id) {
      // update
      await restaurantAPI.update(id, restaurant)
    } else {
      // create
      await restaurantAPI.create(restaurant)
    }
    setSucceededSaved(true)
  }

  if (successfulSaved) {
    return (
      <SuccessfulAction
        title="Tudo certo!"
        message="Restaurante salvo com sucesso."
        returnLink="/"
        returnText="Voltar para o mapa"
      />
    )
  }

  return (
    <>
      <Sidebar />
      <PageContainer>
        <TitleBar title={title} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <legend>Restaurante</legend>
            {loading && <img src={loadingGIF} />}
            {!loading && (
              <>
                <InputBlock>
                  <label htmlFor="name">Nome</label>
                  <input
                    autoFocus
                    name="name"
                    aria-invalid={errors.name ? 'true' : 'false'}
                    defaultValue={restaurant?.name}
                    ref={register}
                  />
                  <span className="error">{errors.name?.message}</span>
                </InputBlock>
                <InputBlock>
                  <label htmlFor="address">Endereço</label>
                  <textarea
                    name="address"
                    aria-invalid={errors.address ? 'true' : 'false'}
                    rows={3}
                    defaultValue={restaurant?.address}
                    ref={register}
                  />
                  <span className="error">{errors.address?.message}</span>
                </InputBlock>
                <InputBlock>
                  <label htmlFor="location.latitude">Latitute</label>
                  <input
                    name="location.latitude"
                    aria-invalid={errors.location?.latitude ? 'true' : 'false'}
                    defaultValue={restaurant?.location?.latitude}
                    ref={register}
                  />
                  <span className="error">
                    {errors.location?.latitude?.message}
                  </span>
                </InputBlock>
                <InputBlock>
                  <label htmlFor="location.longitude">Longitude</label>
                  <input
                    name="location.longitude"
                    aria-invalid={errors.location?.longitude ? 'true' : 'false'}
                    defaultValue={restaurant?.location?.longitude}
                    ref={register}
                  />
                  <span className="error">
                    {errors.location?.longitude?.message}
                  </span>
                </InputBlock>
                <InputBlock>
                  {/* <span className="server-error">{message}</span> */}

                  <button type="submit" className="primary-button">
                    Salvar
                  </button>
                </InputBlock>
              </>
            )}
          </fieldset>
        </Form>
      </PageContainer>
    </>
  )
}

export default RestaurantForm
