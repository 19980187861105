import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    font-size: 62.5%;
  }

  html {
    height: -webkit-fill-available;
  }

  body  {
    width: 100%;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;

    background: ${props => props.theme.colors.background};
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  body,
  input,
  button,
  textarea, .map-popup {
    font: 400 1.8rem/1 "Nunito", sans-serif;
  }

  *:focus {
    outline: none;
  }

  .center {
    text-align: center;
  }

 

  @media (max-width: 767px) {
    body {
      background: #F2F3F5;
    }
  }
`
