import { RouteProps } from 'react-router-dom'

// Auth
import Signin from '../pages/Signin'
import RecoveryPassword from '../pages/RecoveryPassword'
import PasswordReset from '../pages/PasswordReset'

// Restricted
import Home from '../pages/Home'
import VisitForm from '../pages/VisitForm'
// import RestaurantList from '../pages/RestaurantList'
import RestaurantForm from '../pages/RestaurantForm'
import UserList from '../pages/UserList'
import UserForm from '../pages/UserForm'

// Errors
import NotFound from '../pages/NotFound'

// Route specification interface
interface RouteSpec extends RouteProps {
  private?: boolean
}

export const routes: RouteSpec[] = [
  {
    path: '/entrar',
    component: Signin
  },
  {
    path: '/senha/recuperar',
    component: RecoveryPassword,
    exact: true
  },
  {
    path: '/senha/reiniciar',
    component: PasswordReset,
    exact: true
  },
  {
    path: '/',
    component: Home,
    exact: true,
    private: true
  },
  {
    path: '/visitas/criar',
    component: VisitForm,
    exact: true,
    private: true
  },
  {
    path: '/visitas/:id',
    component: VisitForm,
    private: true
  },
  {
    path: '/restaurantes/criar',
    component: RestaurantForm,
    private: true,
    exact: true
  },
  // {
  //   path: '/restaurantes',
  //   component: RestaurantList,
  //   private: true,
  //   exact: true
  // },
  {
    path: '/restaurantes/:id?',
    component: RestaurantForm,
    private: true
  },
  {
    path: '/usuarios/criar',
    component: UserForm,
    private: true,
    exact: true
  },
  {
    path: '/usuarios',
    component: UserList,
    private: true,
    exact: true
  },
  {
    path: '/usuarios/:id?',
    component: UserForm,
    private: true
  },
  {
    component: NotFound
  }
]
