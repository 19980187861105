import styled from 'styled-components'
import backIcon from '../../assets/images/back-blue.svg'

export const Container = styled.div<{ small: boolean }>`
  text-align: ${props => (props.small ? 'center' : 'left')};
  color: ${props => props.theme.colors.titleBar.color};
  padding: ${props => (props.small ? '3rem 0' : '6.4rem 2rem 2.4rem 2rem')};
  max-width: 136.6rem;
  margin: auto;

  z-index: 1000;

  > p {
    font-size: ${props => (props.small ? '1.8rem' : '3.2rem')};
    line-height: 2.8rem;
    font-weight: ${props => (props.small ? '600' : 'bold')};

    color: ${props => (props.small ? 'parent' : '#4d6f80')};
    padding-bottom: ${props => (props.small ? 'initial' : '2.4rem')};
    border-bottom: ${props => (props.small ? 'none' : '1px solid #d3e2e5')};
  }

  > a {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    position: fixed;
    width: 100%;
    top: 0;
    padding: 2.4rem 2.5rem;
    background: ${props => props.theme.colors.titleBar.smallDevice.background};
    /* border-bottom: 1px solid
      ${props => props.theme.colors.titleBar.smallDevice.border}; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);

    > p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      font-weight: 600;
      text-align: center;
      color: ${props => props.theme.colors.titleBar.smallDevice.color};
      border: none;
      padding-bottom: 0;
    }

    > a.back {
      float: left;
      width: 2.5rem;
      height: 2.5rem;
      background: url(${backIcon}) no-repeat center;
    }
  }
`
