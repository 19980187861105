import styled from 'styled-components'

export default styled.ul`
  padding: 0 2rem;

  list-style-type: disc;
  padding-block-start: 1em;
  padding-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  display: grid;
  gap: 1.4rem;

  > li {
    list-style: none;
  }

  @media only screen and (min-width: 960px) {
    width: 100%;
    max-width: 136.6rem !important;
    margin: auto;

    gap: 3.2rem;
  }

  @media only screen and (min-width: 560px) {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
`
