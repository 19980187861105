import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Container } from './styles'

const TitleBar: React.FC<{
  title: string
  small?: boolean
  backLink?: string
}> = ({ title, small = true, backLink = null }) => {
  // hooks
  const history = useHistory()

  // go back to last route
  const goBack = () => {
    history.goBack()
  }

  return (
    <Container small={small}>
      {!backLink && <a className="back" onClick={goBack} />}
      {backLink && <Link to={backLink} className="back" />}
      <p>{title}</p>
    </Container>
  )
}

export default TitleBar
