import { authenticatedRequest } from './config'

// model interface
export interface IUser {
  objectId?: string
  name: string
  username: string
  email: string
  password?: string
  isAdmin: boolean
  active: boolean
  ACL?: {}
}

// default model ACL
const defaultACL = {
  'role:SYSADM': {
    read: true,
    write: true
  },
  'role:ADM': {
    read: true,
    write: true
  }
}

// Gets specific user records allowed to logged user
export const get = (id: string) =>
  authenticatedRequest
    .get(`/users/${id}`)
    .then(response => {
      const user: IUser = response.data
      return user
    })
    .catch(error => {
      return Promise.reject(error)
    })

// Gets all user records allowed to logged user
export const getAll = () =>
  authenticatedRequest
    .get('/users', {
      params: {
        where: {
          email: {
            $ne: 'marcosjalmeida@gmail.com'
          }
        }
      }
    })
    .then(response => {
      const users: IUser[] = response.data.results
      return users
    })
    .catch(error => {
      return Promise.reject(error)
    })

// Creates a user record
export const register = (
  email: string,
  name: string,
  password: string,
  isAdmin: boolean
) => {
  return authenticatedRequest
    .post('/users', {
      username: email,
      email,
      name,
      password,
      isAdmin,
      ACL: defaultACL
    })
    .then(response => {
      console.log(response.data)
      const { objectId } = response.data
      return objectId
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

// Updates a restaurant record
export const update = (id: string, data: IUser) => {
  // sets default ACL for this class
  data.ACL = defaultACL
  // forces username equals email
  data.username = data.email

  return authenticatedRequest
    .put(`/users/${id}`, data)
    .then(() => {})
    .catch(error => {
      return Promise.reject(error)
    })
}
