import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useAuth } from '../../hooks/useAuth'

import { NavLink } from 'react-router-dom'

import InputBlock from '../../components/InputBlock'
import { AuthContainer } from '../../components/AuthContainer'
import { AuthBox } from '../../components/AuthBox'
import AuthBackground from '../../components/AuthBackground'

// validation schema
const schema = yup.object().shape({
  email: yup
    .string()
    .email('Informe um e-mail válido')
    .required('Informe seu e-mail')
})

const RecoveryPassword: React.FC = () => {
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  // hooks
  const auth = useAuth()

  // states
  const [message, setMessage] = useState<string | null>(null)

  // submits data to server
  const onSubmit = (data: any) => {
    const { email } = data

    console.log(email, auth.authState.pending)

    setMessage('Email não encontrado')
    // auth
    //   .signin(email, password)
    //   .then(() => {
    //     // navigates without pushing a new history entry
    //     history.replace(from)
    //   })
    //   .catch((error: any) => {
    //     const { code } = error.response.data
    //     switch (code) {
    //       case 101:
    //         setMessage('Usuário e/ou senha inválidos')
    //         break
    //       default:
    //         setMessage('Erro inesperado. Tente novamente')
    //     }
    //   })
  }

  return (
    <AuthContainer>
      <AuthBackground />
      <AuthBox>
        <header>
          <NavLink to="/entrar" className="back" />
        </header>
        <main>
          <h1>Esqueceu sua senha?</h1>
          <p>
            Informe seu endereço de e-mail que enviaremos instruções para você
            reiniciar sua senha.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputBlock>
              <label htmlFor="name">E-mail</label>
              <input autoFocus name="email" type="email" ref={register} />
              <span className="error">{errors.email?.message}</span>
            </InputBlock>
            <InputBlock>
              <span className="server-error">{message}</span>

              <button
                type="submit"
                disabled={!formState.isValid}
                className="primary-button"
              >
                Recuperar senha
              </button>
            </InputBlock>
          </form>
        </main>
      </AuthBox>
    </AuthContainer>
  )
}

export default RecoveryPassword
