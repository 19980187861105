import React from 'react'

import { ThemeProvider } from 'styled-components'
import GlobalStyle from './styles/global'
import theme from './styles/theme'

import { routes as routesConfig } from './config/routes'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import PrivateRoute from './components/PrivateRoute'
import { AuthProvider } from './hooks/useAuth'

// test
// const AuthButton = () => {
//   const history = useHistory()
//   const auth = useAuth()

//   // eslint-disable-next-line multiline-ternary
//   return auth?.authState.user ? (
//     <p>
//       {`Welcome, ${auth.authState.user.email}`}
//       <button
//         onClick={() => {
//           auth?.signout(() => history.push('/'))
//         }}
//       >
//         Sign out
//       </button>
//     </p>
//   ) : (
//     <p>
//       You are not logged in.{' '}
//       <button
//         onClick={() => {
//           auth?.signin('user@gmail.com', '123456')
//         }}
//       >
//         Sign IN
//       </button>
//     </p>
//   )
// }

// entry point
const App: React.FC = () => {
  const routes = routesConfig.map((route, index) =>
    // eslint-disable-next-line multiline-ternary
    route.private ? (
      <PrivateRoute
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ) : (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    )
  )

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          {/* <Link to="/">Home</Link>
          <AuthButton /> */}
          <Switch>{routes}</Switch>
          {/* <br />
          <br />
          <br />
          <button onClick={() => history.back()}>Voltar</button> */}
        </BrowserRouter>
        <GlobalStyle />
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
