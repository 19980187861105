import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useAuth } from '../../hooks/useAuth'

import InputBlock from '../../components/InputBlock'
import { AuthContainer } from '../../components/AuthContainer'
import { AuthBox } from '../../components/AuthBox'
import AuthBackground from '../../components/AuthBackground'

// validation schema
const schema = yup.object().shape({
  password: yup.string().required('Informe sua nova senha'),
  passwordConfirm: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'Senha não confere com a digitada anteriormente'
    )
})

const RecoveryPassword: React.FC = () => {
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  // hooks
  const auth = useAuth()

  // states
  const [message, setMessage] = useState<string | null>(null)

  // submits data to server
  const onSubmit = (data: any) => {
    const { email } = data

    console.log(email, auth.authState.pending)

    setMessage('Email não encontrado')
    // auth
    //   .signin(email, password)
    //   .then(() => {
    //     // navigates without pushing a new history entry
    //     history.replace(from)
    //   })
    //   .catch((error: any) => {
    //     const { code } = error.response.data
    //     switch (code) {
    //       case 101:
    //         setMessage('Usuário e/ou senha inválidos')
    //         break
    //       default:
    //         setMessage('Erro inesperado. Tente novamente')
    //     }
    //   })
  }

  return (
    <AuthContainer>
      <AuthBackground />
      <AuthBox>
        <main>
          <h1>Redefinir sua senha</h1>
          <p>Escolha uma nova senha com pelo menos 8 caracteres.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputBlock>
              <label htmlFor="password">Nova senha</label>
              <input autoFocus name="password" type="password" ref={register} />
              <span className="error">{errors.password?.message}</span>
            </InputBlock>
            <InputBlock>
              <label htmlFor="name">Confirmação nova senha</label>
              <input name="passwordConfirm" type="password" ref={register} />
              <span className="error">{errors.passwordConfirm?.message}</span>
            </InputBlock>
            <InputBlock>
              <span className="server-error">{message}</span>

              <button
                type="submit"
                disabled={!formState.isValid}
                className="primary-button"
              >
                Redefinir senha
              </button>
            </InputBlock>
          </form>
        </main>
      </AuthBox>
    </AuthContainer>
  )
}

export default RecoveryPassword
