import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { userAPI } from '../../services/api'
import { IUser } from '../../services/api/user'

import Sidebar from '../../components/Sidebar'
import PageContainer from '../../components/PageContainer'
import TitleBar from '../../components/TitleBar'
import Form from '../../components/Form'
import InputBlock from '../../components/InputBlock'

import loadingGIF from '../../assets/images/loading.gif'
import SuccessfulAction from '../../components/SuccessfulAction'
import { Container } from './styles'

// validation schema
const schema = yup.object().shape({
  name: yup.string().required('Informe o nome'),
  username: yup
    .string()
    .email('Informe um e-mail válido')
    .required('Informe o e-mail'),
  password: yup
    .string()
    .test(
      'empty-or-6-characters-check',
      'Senha deve ter pelo menos 6 caracters',
      password => !password || password.length >= 6
    )
    .when('$user', (user: IUser, schema: any) =>
      !user ? schema.required('Informe a senha') : schema
    )
})

const UserForm: React.FC = () => {
  // hooks
  const { id } = useParams<{ id: string }>()

  // states
  const [user, setUser] = useState<IUser | null>(null)
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('Novo')

  const [successfulSaved, setSucceededSaved] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [active, setActive] = useState(true)

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    context: { user },
    mode: 'onChange'
  })

  // loads initial data
  useEffect(() => {
    // loads object data if id is present
    if (id) {
      setLoading(true)
      userAPI
        .get(id)
        .then(user => {
          setUser(user)
          setIsAdmin(user.isAdmin)
          setActive(user.active)
        })
        .catch((error: any) => {
          const { code } = error.response.data
          switch (code) {
            // not found
            case 101:
              setUser(null)
              break
            default:
            // TODO: handler other errors
          }
        })
        .finally(() => {
          setLoading(false)
          setTitle('Editar')
        })
    }
  }, [])

  if (id && !user && !loading) {
    return <></>
  }

  // submits form data
  const onSubmit = async (data: IUser) => {
    const user = data
    user.isAdmin = isAdmin
    user.active = active

    if (id) {
      if (data.password === '') {
        delete data.password
      }
      // update
      await userAPI.update(id, user)
    } else {
      // register
      await userAPI.register(
        user.email,
        user.name,
        user.password || '',
        isAdmin
      )
    }
    setSucceededSaved(true)
  }

  if (successfulSaved) {
    return (
      <SuccessfulAction
        title="Tudo certo!"
        message="Usuário salvo com sucesso."
        returnLink="/usuarios"
        returnText="Voltar para lista"
      />
    )
  }

  return (
    <Container>
      <Sidebar />
      <PageContainer>
        <TitleBar title={title} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <legend>Usuário</legend>
            {loading && <img src={loadingGIF} />}

            <InputBlock>
              <label htmlFor="name">Nome</label>
              <input
                autoFocus
                name="name"
                aria-invalid={errors.name ? 'true' : 'false'}
                defaultValue={user?.name}
                ref={register}
              />
              <span className="error">{errors.name?.message}</span>
            </InputBlock>
            <InputBlock>
              <label htmlFor="username">E-mail</label>
              <input
                name="username"
                type="email"
                readOnly
                aria-invalid={errors.username ? 'true' : 'false'}
                defaultValue={user?.username}
                ref={register}
              />
              <span className="error">{errors.username?.message}</span>
            </InputBlock>
            <InputBlock>
              <label htmlFor="password">
                Senha
                {user && <span>Deixe em branco para manter a senha atual</span>}
              </label>
              <input
                name="password"
                type="password"
                aria-invalid={errors.password ? 'true' : 'false'}
                defaultValue={user?.password}
                ref={register}
              />
              <span className="error">{errors.password?.message}</span>
            </InputBlock>
            <InputBlock>
              <label htmlFor="isAdmin">Administrador?</label>
              <div className="button-select">
                <button
                  type="button"
                  className={isAdmin ? 'active' : ''}
                  onClick={() => setIsAdmin(true)}
                >
                  Sim
                </button>
                <button
                  type="button"
                  className={isAdmin ? '' : 'active'}
                  onClick={() => setIsAdmin(false)}
                >
                  Não
                </button>
              </div>
            </InputBlock>
            <InputBlock>
              <label htmlFor="active">Ativo?</label>
              <div className="button-select">
                <button
                  type="button"
                  className={active ? 'active' : ''}
                  onClick={() => setActive(true)}
                >
                  Sim
                </button>
                <button
                  type="button"
                  className={active ? '' : 'active'}
                  onClick={() => setActive(false)}
                >
                  Não
                </button>
              </div>
            </InputBlock>

            <InputBlock>
              {/* <span className="server-error">{message}</span> */}

              <button type="submit" className="primary-button">
                Salvar
              </button>
            </InputBlock>
          </fieldset>
        </Form>
      </PageContainer>
    </Container>
  )
}

export default UserForm
